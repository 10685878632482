import mobilePreviewIMG from "./../../assets/image/mobile/mobilePreviewIMG.png";
import labIcon from "./../../assets/image/mobile/labIcon.png";
import QRLabIOS from "./../../assets/image/mobile/QRLabIOS.svg";
import QRLabAndroid from "./../../assets/image/mobile/QRLabAndroid.svg";
import { motion } from "framer-motion";

const mobileAnimation = {
  hidden: {
    x: 0,
    opacity: 0,
    scale: 0.9,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: custom * 0.2 },
  }),
};

const Mobile = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      className="mobile"
    >
      <motion.div className="mobile__block" variants={mobileAnimation}>
        <div className="mobile__info">
          <img className="mobile__icon" src={labIcon} alt="" />
          <div custom={2} className="mobile__title">
            Mobile NIS Lab
          </div>
        </div>
        <div className="mobile__qrs__wrapper">
          <div className="mobile__qr__subtitle">
            Доступен на мобильных устройствах.
          </div>
          <div className="mobile__qrs">
            <div className="mobile__qr">
              <img className="mobile__qr__img" src={QRLabIOS} alt="" />
              <a href="https://apps.apple.com/kz/app/nis-lab/id6467727087" class="mobile__qr__url">NIS Lab на IOS</a>
            </div>
            <div className="mobile__qr">
              <img className="mobile__qr__img" src={QRLabAndroid} alt="" />
              <a href="https://play.google.com/store/apps/details?id=cep.nis.nislab.kz" class="mobile__qr__url">NIS Lab на Android</a>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div variants={mobileAnimation} className="mobile__img--wrapper">
        <img src={mobilePreviewIMG} className="mobile__img" alt="mobile" />
      </motion.div>
    </motion.section>
  );
};

export default Mobile;
